import React from 'react'
import { css } from '@emotion/react'
import { Link } from 'gatsby'

import { mq } from '@/components/media/media'
import { PageLayout } from '@/components/PageLayout'
import { Head } from '@/components/head'

export default function Policy(): JSX.Element {
  return (
    <>
      <Head
        title="プライバシーポリシー| 株式会社Relicホールディングス"
        url="policy"
      />
      <PageLayout
        title="プライバシーポリシー"
        subTitle="PRIVACY POLICY"
        breadcrumbs="/PRIVACY POLICY"
        breadcrumbsPath="/policy"
      >
        <section>
          <div css={cssLyContentsBody}>
            <h2>プライバシーポリシー</h2>
            <div
              css={css`
                margin-top: 24px;
              `}
            >
              <div css={cssMb}>
                株式会社Relicホールディングス（以下、「当社」といいます）は、グループ会社とともにお客様と挑戦し新しい価値を共創してゆくことを目指しています。Relicグループ全体では、当社が取得した個人情報の取扱いに関し、個人情報の保護に関する法律、個人情報保護に関するガイドライン等の指針、その他個人情報保護に関する関係法令を遵守します。その上で、お客様の個人情報に最大限の注意を払い、お客様の個人情報を保護することが、当社の事業活動の基本であると共に、当社の社会的責務であると考えております。
                <br />
                当社は、責任をもってお客様の個人情報を保護するため、個人情報の保護に関し以下のとおり定め、社内体制の構築、施策の実施・維持、それらの継続的な改善を行っています。
              </div>
              <div css={cssMb}>
                <h2>適用範囲</h2>
                本プライバシーポリシーは当社に関する全てのサービス（以下、「当社サービス」といいます）及び採用などの企業活動全般に適用されます。各サービス及び採用等の特設ページごとのプライバシーポリシーまたは利用規約等（以下、「個別規約」といいます）において個別に個人情報の取扱いを規定する場合は、個別規約も適用されます。本プライバシーポリシーと個別規約において矛盾・抵触が発生する場合は個別規約が優先されます。
              </div>
              <div css={cssMb}>
                <h2>お客様の個人情報の収集について</h2>
                当社では、当社サービスをご利用いただくために、お客様の個人情報をご提供いただくことがあります。原則として、お客様にお断りなく、お客様ご自身から個人情報を収集することはございません。当社がお客様から個人情報をご提供いただく場合には、その利用目的を明示した上で個人情報の収集をいたします（ただし、お客様から個人情報をご提供いただけない場合には、ご利用できない当社サービスがございますことをあらかじめご了承ください。）。また、当社は、以下の方法でお客様の情報を収集することがあります。
                <br />
                (1)当社サービスが自動で取得するご利用者の情報
                <ul css={cssMb}>
                  <li>・アクセスした日時</li>
                  <li>・ブラウザの種類(User-Agent)</li>
                  <li>・表示を要求したURL</li>
                  <li>・リファラ</li>
                  <li>・IPアドレス</li>
                  <li>・Cookie(クッキー)</li>
                </ul>
                (2)当社サービスが外部サービスとの連携により取得する情報
                <br />
                お客様が外部サービスとの連携を許可した場合、以下の情報を外部サービスから取得します。これらの利用者情報の保護については、当該外部サービスのプライバシーポリシーをご確認ください。
                <ul css={cssMb}>
                  <li>・外部サービスでのお客様ID</li>
                  <li>
                    ・その他外部サービスのプライバシーポリシー設定によりお客様が連携先に開示を認めた情報
                  </li>
                </ul>
              </div>
              <div css={cssMb}>
                <h2>お客様の個人情報の利用について</h2>
                当社は、収集した個人情報を、以下の目的のほか、当社サービスにおいてあらかじめお客様に明示した目的のために利用いたします。明示した目的の範囲を超えてお客様の個人情報を利用する必要が生じた場合には、その旨お客様にお知らせいたします。
                <br />
                (1)当社サービスのご利用者の情報
                <ul css={cssMb}>
                  <li>・利用登録に関する各種手続</li>
                  <li>
                    ・ご利用いただく当社サービスのご提供及び関連する情報のご案内
                  </li>
                  <li>・当社におけるサービスの開発・改善のための分析</li>
                  <li>
                    ・当社サービス及びそれに関連する情報に関するアンケート・調査等のご依頼
                  </li>
                  <li>
                    ・当社サービスに関する当社の規約、ポリシー等（以下、「規約等」といいます。）に違反する行為に対する対応
                  </li>
                  <li>・当社サービスに関する規約等の変更等の通知</li>
                  <li>
                    ・当社ホームページまたは求人広告（Web
                    就職サイト、求人誌等）を通じて応募の受付
                  </li>
                  <li>・資料送付、セミナーの開催案内等</li>
                  <li>・お問い合わせ等への対応</li>
                </ul>
                (2)お取引先の役職員の情報
                <ul css={cssMb}>
                  <li>・お取引先の管理</li>
                  <li>・当社サービス及びそれに関連する情報のご案内</li>
                  <li>
                    ・当社サービス及びそれに関連する情報に関するアンケート・調査等のご依頼
                  </li>
                  <li>・業務上のご連絡及び契約の履行</li>
                  <li>・お問い合わせ等への対応</li>
                </ul>
                (3)当社求人に応募された方の情報
                <ul>
                  <li>・採用に関する選考、決定及び入社手続き</li>
                  <li>・入社後の雇用管理</li>
                </ul>
                採用の手続きにおける個人情報について
                <br />
                採用活動において収集した個人情報は、事前の承諾なしに、それ以外の目的での利用は一切いたしません。
                <br />
                個人情報の登録、提出については、あくまで応募者の任意となりますが、必要事項をご記入、ご提出頂けない場合、応募を受け付けられないことがありますのでご了承下さい。
                <br />
                採用活動において採用後の適正な配置を行うために身体状況に関する情報を収集することがあります。
                <br />
                応募書類、データ（履歴書、エントリーシート等）は返却いたしません。採用選考終了後、当社規定に従い安全に廃棄させていただきます。
                <br />
                ※採用に至った場合には、その後の雇用管理に利用いたします。
              </div>
              <div css={cssMb}>
                <h2>個人関連情報</h2>
                当社は、第三者から、識別子や行動履歴など、お客様に関連する情報（個人関連情報）の提供を受け、当該情報を当社が管理するお客様の個人情報と紐づけることで個人情報として取得することがあります。具体的には、Cookie、広告識別子、メールアドレス、電話番号、広告配信関連ログ、ウェブサイトの閲覧履歴や検索履歴、位置情報その他の行動履歴等を取得し、当社が管理する個人情報と紐づけて利用する場合があります。この場合は、「お客様の個人情報の利用について」記載の利用目的の達成に必要な範囲で、個人情報として適切に取り扱います。
              </div>
              <div css={cssMb}>
                <h2>お客様の個人情報の第三者への提供について</h2>
                当社は、お客様の個人情報について、以下の各号のいずれかに該当する場合を除き、第三者に提供することはありません。
                <ul css={cssMb}>
                  <li>・お客様の事前の同意・承諾を得た場合。</li>
                  <li>
                    ・利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合。
                  </li>
                  <li>
                    ・合併その他の事由による事業の承継に伴って個人情報が提供される場合。
                  </li>
                  <li>
                    ・生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき。
                  </li>
                  <li>
                    ・国の機関若しくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき。
                  </li>
                  <li>・その他法令に基づく場合。</li>
                </ul>
              </div>
              <div css={cssMb}>
                <h2>共同利用</h2>
                当社は、以下に掲げるとおり個人情報の共同利用を行います。
                <br />
                (1)共同利用する個人情報の項目
                <br />
                <ul css={cssMb}>
                  <li>
                    ・氏名、所属組織名、役職名、住所、メールアドレス、電話番号、FAX番号、その他当社にお申込みいただいた情報
                  </li>
                  <li>・お取引や商談、ご連絡等の履歴</li>
                  <li>・「お客様の個人情報の収集について」に掲げる情報</li>
                </ul>
                <div css={cssMb}>
                  (2)共同利用する個人情報の利用目的
                  <br />
                  「お客様の個人情報の利用について」に掲げる利用目的
                </div>
                <div css={cssMb}>
                  (3)共同利用者の範囲
                  <br />
                  当社のグループ会社（グループ会社一覧は
                  <Link to="/group/">こちら</Link>をご参照ください）
                </div>
                <div css={cssMb}>
                  (4)共同利用の管理責任者
                  <br />
                  株式会社Relicホールディングス
                  <br />
                  住所及び代表者の氏名については、以下のページをご参照ください。
                  <br />
                  <a
                    href="https://relic-holdings.co.jp/company"
                    target="_blank"
                    rel="noopener"
                  >
                    https://relic-holdings.co.jp/company
                  </a>
                </div>
              </div>
              <div css={cssMb}>
                <h2>委託先の監督</h2>
                当社は、利用目的の達成に必要な範囲内において、お預かりした個人情報の取扱いを第三者に委託する場合があります。これらの第三者は、個人情報の取扱いにつき、十分なセキュリティ水準にあることを確認の上で選定し、契約等を通じて、必要かつ適切な監督を行います。
              </div>
              <div css={cssMb}>
                <h2>個人情報の管理について</h2>
                当社は、お客様の個人情報を適切、厳重に管理し、お客様の個人情報への不正なアクセスや情報の紛失、破壊、改ざん及び漏洩等が起きないよう、予防及び安全対策を講じております。
              </div>
              <div css={cssMb}>
                <h2>業務運営について</h2>
                当社は、個人情報の保護に関する法律及びその関連法令並びにその他の規範に則った業務運営に努めてまいります。
              </div>
              <div css={cssMb}>
                <h2>譲渡について</h2>
                当社サービスや資産等の全部または一部が第三者へ譲渡された場合、あるいは別の企業と合併した場合は、取得された個人情報は当該第三者へ譲渡します。
              </div>
              <div css={cssMb}>
                <h2>継続的改善について</h2>
                当社は、個人情報の保護及び取扱いについて、継続的改善に努めてまいります。
              </div>
              <div css={cssMb}>
                <h2>
                  第三者のウェブサイト、アプリケーションとのリンクについて
                </h2>
                お客様がアカウントをお持ちのソーシャルネットワークサービス（SNS）や他社のサービスとの相互連携のため、当社サービスからログイン、投稿などができる機能の組込みを行うことがあります。
              </div>

              <div css={cssMb}>
                <h2>Cookie等の使用について</h2>
                当社のウェブサイト及び当社サービスでは、お客様へのサービス向上、広告配信及び統計データの取得などの用途でCookieを使用しております。
                <br />
                ※Cookieには氏名、住所、電話番号などの個人を識別する情報は含まれません
                <br />
                Cookieを使用した情報収集を受けたくない場合には、ウェブブラウザの設定でCookieの受け入れを拒否することができます。なお、Cookieの受け入れを拒否した場合、当社のウェブサイト及び当社サービスの一部をご利用いただけなくなることがございますのでご了承ください。
                <br />
                <br />
                また、当社が提供するサービスでは、各事業者が提供するサービスを用いてお客様への広告配信や当社サービスのご利用状況の調査・分析などに利用しています。サービスの一覧は
                <Link to="/service/">こちら</Link>をご確認ください。
              </div>
              <div css={cssMb}>
                <h2>個人情報の開示等</h2>
                当社は、お客様から自らの個人情報の開示、訂正、追加、削除、利用停止、消去、第三者提供に関する記録の開示若しくは提供の停止、利用目的の通知または安全管理措置の内容の開示等（以下、これらを総称して「開示等」といいます）の請求を受けた場合は、当社所定の手続きに基づき、必要に応じご本人であることを確認させていただいた上で、速やかに対応いたします。
                <br />
                ただし、法令の定めにより当社が開示等の義務を負わない場合はこの限りではありません。なお、個人情報の利用停止や消去等によって、当社サービスのご提供ができなくなることがございますのでご了承ください。
                <br />
                手続きの詳細につきましては、お問い合わせ窓口でご確認ください。
                <br />
                また、利用目的の通知、お客様の個人情報の開示または第三者提供記録の開示につきましては、手数料（1件あたり1,000円）を頂戴しておりますので、あらかじめご了承ください。
              </div>
              <div css={cssMb}>
                <h2>当社の住所及び代表者の氏名</h2>
                当社の住所及び代表者の氏名については、以下のページをご参照ください。
                <br />
                <br />
                株式会社Relicホールディングス&emsp;
                <a
                  href="https://relic-holdings.co.jp/company"
                  target="_blank"
                  rel="noopener"
                >
                  https://relic-holdings.co.jp/company
                </a>
                <br />
                株式会社Relic&emsp;
                <a
                  href="https://relic.co.jp/company/overview/"
                  target="_blank"
                  rel="noopener"
                >
                  https://relic.co.jp/company/overview/
                </a>
                <br />
                株式会社Rebootus&emsp;
                <a
                  href="https://relic-holdings.co.jp/group/rebootus#company"
                  target="_blank"
                  rel="noopener"
                >
                  https://relic-holdings.co.jp/group/rebootus#company
                </a>
                <br />
                株式会社Scalehack&emsp;
                <a
                  href="https://scalehack.co.jp/company/"
                  target="_blank"
                  rel="noopener"
                >
                  https://scalehack.co.jp/company/
                </a>
                <br />
                株式会社CAMPFIRE ENjiNE&emsp;
                <a
                  href="https://relic-holdings.co.jp/group/campfire-enjine#company"
                  target="_blank"
                  rel="noopener"
                >
                  https://relic-holdings.co.jp/group/campfire-enjine#company
                </a>
                <br />
                株式会社MEAL FORWARD&emsp;
                <a
                  href="https://mealforward.co.jp/company"
                  target="_blank"
                  rel="noopener"
                >
                  https://mealforward.co.jp/company
                </a>
                <br />
                株式会社ALTILAN&emsp;
                <a
                  href="https://relic-holdings.co.jp/group/altilan#company"
                  target="_blank"
                  rel="noopener"
                >
                  https://relic-holdings.co.jp/group/altilan#company
                </a>
              </div>

              <div css={cssMb}>
                <h2>お問い合わせ窓口</h2>
                当社における個人情報の取扱いに関するご意見・ご相談等につきましては、下記窓口へご連絡ください。
                <br />
                住所：〒150-6019
                東京都渋谷区恵比寿4丁目20番3号 恵比寿ガーデンプレイスタワー19F
                <br />
                株式会社Relicホールディングス
                <br />
                個人情報取扱責任者：Co-Creator Experience部長
                <br />
                連絡先：<a href="mailto:info@relic.co.jp">info@relic.co.jp</a>
              </div>
              <div css={cssMb}>
                <h2>プライバシーポリシーの変更</h2>
                当社では、お客様の個人情報の保護を図るために、または法令等の変更に対応するために、本プライバシーポリシーの内容を変更することがあります。変更の際には、当社ウェブサイトへの掲載や電子メール等によりお知らせいたします。
              </div>
            </div>
          </div>
        </section>
      </PageLayout>
    </>
  )
}

const cssLyContentsBody = css`
  padding: 10px 16px 60px;
  ${mq.pc} {
    max-width: 1080px;
    padding: 20px 16px 120px;
    margin: 0 auto;
  }
`

const cssMb = css`
  margin-bottom: 24px;
`
