import React from 'react'

import { PageLayout } from '@/components/PageLayout'
import { Head } from '@/components/head'
import { GroupAndServiceData } from '@/components/common/data-list'
import { groupCompanies } from '@/constants/groupCompanies'

export default function Company(): JSX.Element {
  return (
    <>
      <Head
        title="グループ会社一覧 | 株式会社Relicホールディングス"
        url="group"
        noindex={true}
      />
      <PageLayout
        title="グループ会社一覧"
        subTitle="GROUP COMPANIES"
        breadcrumbs="/GROUP COMPANIES"
        breadcrumbsPath="/group"
      >
        <ul>
          {groupCompanies.map((company) => (
            <GroupAndServiceData
              title={company.title}
              url={company.url}
              isTargetBlank={company.isTargetBlank}
            />
          ))}
        </ul>
      </PageLayout>
    </>
  )
}
